<template>
  <v-container fluid>
    <v-card class="mb-8">
      <v-card-text>
        <v-row class="ma-0">
          <v-col cols="12" md="6">
            <form-date-picker
              label="日期範圍"
              :fieldValue.sync="dateRange"
              isDateRange
              disableFuture
            />
          </v-col>

          <v-col cols="12">
            <v-btn
              depressed
              color="primary accent-4"
              class="white--text"
              type="button"
              :loading="$store.getters.isLoading"
              @click="exportSalesReport()"
              >SALES REPORT</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn
              depressed
              color="primary accent-4"
              class="white--text"
              type="button"
              :loading="$store.getters.isLoading"
              @click="exportAppointmentReport()"
              >APPOINTMENT REPORT</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ExcelMixin from '@/mixins/ExcelMixin.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'

export default {
  name: 'Member',
  mixins: [ExcelMixin],
  components: {
    FormDatePicker,
  },
  data: () => ({
    dateRange: [],
  }),
  methods: {
    ...mapActions(['setAlert']),

    async exportSalesReport() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'processing',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      try {
        const user = this.getCurrentUserData()
        let payload = {}
        if (this.dateRange.length > 0) {
          payload['start_date'] = this.dateRange[0]
          if (this.dateRange.length > 1 && this.dateRange[1] !== this.dateRange[0]) {
            payload['end_date'] = this.dateRange[1]
          }
        }
        const { data } = await this.$Fetcher.GetSalesReport(payload, user.id, user.token)

        const header = {
          create_date: '購買日期',
          package_name: '套票名稱',
          package_id: '套票ID',
          subtotal: '小計',
          discount: '折扣',
          total: '總計',
          username: '用戶名稱',
          user_id: '用戶ID',
          email: '用戶電郵',
        }

        const list = data.map(item => {
          return {
            create_date: this.$Formatter.displayCreateDate(item.create_date),
            package_name: item.coupon_group_data ? item.coupon_group_data.name : '',
            package_id: item.coupon_group_id,
            subtotal: `$${item.coupon_group_data ? item.coupon_group_data.price : ''}`,
            discount: `$${item.discount}`,
            total: `$${item.total}`,
            username: item.user_data ? item.user_data.name : '',
            user_id: item.user_id,
            email: item.user_data ? item.user_data.email : '',
          }
        })

        await this.exportData(header, list, 'sales_report')
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        } else {
          this.$store.dispatch('toggleSnack', {
            type: 'info',
            message: '沒有資料可以匯出',
          })
        }
        this.$store.dispatch('setLoading', false)
      }
    },

    async exportAppointmentReport() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'processing',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      try {
        const user = this.getCurrentUserData()
        let payload = {}
        if (this.dateRange.length > 0) {
          payload['start_date'] = this.dateRange[0]
          if (this.dateRange.length > 1 && this.dateRange[1] !== this.dateRange[0]) {
            payload['end_date'] = this.dateRange[1]
          }
        }
        const { data } = await this.$Fetcher.GetAppointmentReport(payload, user.id, user.token)

        const header = {
          create_date: '申請日期',
          appointment_date: '預約日期',
          appointment_time: '預約時間',
          status: '狀態',
          name: '姓名',
          phone: '電話',
          email: '電郵',
          remark: '備註',
        }

        const list = data.map(item => {
          const status = this.$appointmentStatusOptions.find(el => el.value === item.appointment_status)
          return {
            create_date: this.$Formatter.displayCreateDate(item.create_date),
            appointment_date: item.appointment_date.substring(0, 10),
            appointment_time: item.appointment_date.substring(11, 16),
            status: status ? status.text : item.appointment_status,
            name: item.user_data ? item.user_data.name : '',
            phone: item.user_data ? item.user_data.phone : '',
            email: item.user_data ? item.user_data.email : '',
            remark: item.remark,
          }
        })

        await this.exportData(header, list, 'appointment_report')
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        } else {
          this.$store.dispatch('toggleSnack', {
            type: 'info',
            message: '沒有資料可以匯出',
          })
        }
        this.$store.dispatch('setLoading', false)
      }
    },

    async exportData(header, list, title) {
      try {
        this.exportExcel(header, list, title, `${title}.xlsx`)
      } catch (err) {
        this.$common.error(err)
        this.$store.dispatch('toggleSnack', {
          show: true,
          type: 'error',
          message: '匯出失敗',
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },
  },
}
</script>
